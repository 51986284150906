import React from "react";
import styled from "@emotion/styled";

const Row = ({ image, text, subtext, height, width }) => {
  const CustomRowWrapper = styled.div`
    border-bottom: 1px solid #d7dbdc;
    margin-bottom: 11px;
    padding-bottom: 8px;
  `;

  const CustomRow = styled.div`
    display: flex;
  `;

  const ImageTag = styled.img`
    margin-right: 14px;
    height: 60px;
    width: 60px;
  `;

  const CustomPara = styled.p``;

  const Subtext = styled.div``;

  const CustomParaWrapper = styled.div`
    max-width: 80%;

    @media screen and (min-width: 768px) {
      max-width: 660px;
    }
  `;

  return (
    <CustomRowWrapper>
      <CustomRow>
        {height && width ? (
          <img
            src={image}
            alt="icon"
            height={height}
            width={width}
            style={{ marginRight: "16px" }}
          />
        ) : (
          <ImageTag src={image} alt="icon" />
        )}

        <CustomParaWrapper>
          <CustomPara dangerouslySetInnerHTML={{ __html: text }} />

          {subtext ? (
            <Subtext>
              <p>{subtext}</p>
            </Subtext>
          ) : (
            ""
          )}
        </CustomParaWrapper>
      </CustomRow>
    </CustomRowWrapper>
  );
};

export default Row;
