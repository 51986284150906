import React from "react";
import Seo from "../components/seo";
import HeadingWithSub from "../components/HeadingWithSub";
import styled from "@emotion/styled";
import Row from "../components/Row";
import icon1 from "../images/tips/icon-01.svg";
import icon2 from "../images/tips/icon-02.svg";
import icon3 from "../images/tips/icon-03.svg";
import icon5 from "../images/tips/icon-05.svg";
import icon6 from "../images/tips/icon-06.svg";
import icon7 from "../images/tips/icon-07.svg";
import icon8 from "../images/tips/icon-08.svg";
import icon9 from "../images/tips/icon-09.svg";
import icon10 from "../images/tips/icon-10.svg";
import icon11 from "../images/tips/icon-11.svg";
import ParentRouteHandler from "../components/ParentRouteHandler";

const TipsForFamilies = ({ location, data }) => {
  const Container = styled.div`
    margin-top: 23px;
  `;

  return (
    <>
      <Seo title="Tips for Families" />
      {location.state &&
      location.state.originalPath &&
      location.state.originalPath === "home" ? (
        <ParentRouteHandler title="Home" path="/" />
      ) : (
        <ParentRouteHandler title="For Families" path="/for-families" />
      )}

      <HeadingWithSub main={`Tips for Families`} subTitle={``} />
      <Container>
        <Row
          text="Stay together. Exploring the museum is always more fun with your group! We recommend that adults hold hands with younger children in the galleries."
          image={icon1}
        />

        <Row
          text="Art is for your eyes and not your hands. Partner with us in preserving the artwork for future generations!"
          image={icon2}
        />

        <Row
          text="Baby bottles are permitted in the galleries. No other food/drink is permitted in the museum. Food may be taken out to the plaza to eat."
          image={icon3}
        />

        <Row
          text="Backpacks must be worn on the front of your body or carried by hand at your side. Child-carrying backpacks are not allowed in the galleries."
          image={icon5}
        />

        <Row
          text="All of The Broad’s public restrooms have diaper changing tables."
          image={icon6}
        />

        <Row
          text="Walk, don’t run. We don’t want you to miss anything."
          image={icon7}
        />

        <Row
          text="You are welcome to take photographs of the collection for personal use unless otherwise noted. Please do not use flashes, tripods, video cameras, easels, or selfie sticks."
          image={icon8}
        />

        <Row
          text="We have quiet spaces for nursing. Please ask a Visitor Experience Team Member for assistance."
          image={icon9}
        />

        <Row
          text="Standard strollers are allowed in the galleries. Need a stroller? See a Visitor Experience Team Member in the lobby to borrow a free stroller to use in the museum."
          image={icon10}
        />

        <Row
          text="Want to know where the bathrooms are, or what the giant balloon dog is made out of? We have many ways you can ask a Visitor Experience Team Member."
          image={icon11}
          subtext="The friendly looking staff dressed in black are here to help you, so feel free to ask them questions throughout your visit."
        />
      </Container>
    </>
  );
};

export default TipsForFamilies;
